import { render, staticRenderFns } from "./Question478.vue?vue&type=template&id=4f78e76e&scoped=true&"
import script from "./Question478.vue?vue&type=script&lang=js&"
export * from "./Question478.vue?vue&type=script&lang=js&"
import style0 from "./Question478.vue?vue&type=style&index=0&id=4f78e76e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f78e76e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VForm,VRow})
