<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Consider each of the hypothetical electron configurations for a chlorine atom/ion:
      </p>

      <p class="mb-3 pl-8">
        <stemble-latex
          content="$\text{A.}\,\,\,\text{1s}^2\,\text{2s}^2\,\text{2p}^6\,\text{3s}^3\,\text{3p}^4$"
        />
      </p>

      <p class="mb-3 pl-8">
        <stemble-latex
          content="$\text{B.}\,\,\,\text{1s}^2\,\text{2s}^2\,\text{2p}^6\,\text{3s}^1\,\text{3p}^6$"
        />
      </p>

      <p class="mb-3 pl-8">
        <stemble-latex
          content="$\text{C.}\,\,\,\text{1s}^2\,\text{2s}^2\,\text{2p}^6\,\text{3s}^2\,\text{2d}^5$"
        />
      </p>

      <p class="mb-3 pl-8">
        <stemble-latex
          content="$\text{D.}\,\,\,\text{1s}^2\,\text{2s}^2\,\text{2p}^6\,\text{3s}^2\,\text{3p}^5$"
        />
      </p>

      <p class="mb-3 pl-8">
        <stemble-latex
          content="$\text{E.}\,\,\,\text{1s}^2\,\text{2s}^2\,\text{2p}^6\,\text{3s}^2\,\text{3p}^6$"
        />
      </p>

      <p class="mb-5 pl-8">
        <stemble-latex
          content="$\text{F.}\,\,\,\text{1s}^2\,\text{2s}^2\,\text{2p}^7\,\text{3s}^2\,\text{3p}^6$"
        />
      </p>

      <p class="mb-4">
        Indicate the electron configuration(s) that fit each of the descriptions below:
      </p>

      <p class="mb-0">a) The correct ground state configuration for the neutral atom</p>

      <v-row class="pl-8 mb-2">
        <v-checkbox
          v-for="option in options"
          v-model="inputs.groundState"
          :key="'pt-1-' + option.value"
          :value="option.value"
          class="pr-8"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-0">
        b) The correct configuration for the <b>most stable charged state</b> of chlorine
      </p>

      <v-row class="pl-8 mb-2">
        <v-checkbox
          v-for="option in options"
          v-model="inputs.stableIon"
          :key="'pt-2-' + option.value"
          :value="option.value"
          class="pr-8"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-0">c) An excited state</p>

      <v-row class="pl-8 mb-2">
        <v-checkbox
          v-for="option in options"
          v-model="inputs.excitedState"
          :key="'pt-3-' + option.value"
          :value="option.value"
          class="pr-8"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-0">d) A state that is forbidden by the Pauli Exclusion Principle</p>

      <v-row class="pl-8 mb-2">
        <v-checkbox
          v-for="option in options"
          v-model="inputs.breaksPauli"
          :key="'pt-4-' + option.value"
          :value="option.value"
          class="pr-8"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-0">e) A configuration with non-existent orbitals</p>

      <v-row class="pl-8 mb-2">
        <v-checkbox
          v-for="option in options"
          v-model="inputs.nonExistentOrbs"
          :key="'pt-5-' + option.value"
          :value="option.value"
          class="pr-8"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question478',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        groundState: [],
        stableIon: [],
        excitedState: [],
        breaksPauli: [],
        nonExistentOrbs: [],
      },
      options: [
        {text: '$\\text{A}$', value: 'A'},
        {text: '$\\text{B}$', value: 'B'},
        {text: '$\\text{C}$', value: 'C'},
        {text: '$\\text{D}$', value: 'D'},
        {text: '$\\text{E}$', value: 'E'},
        {text: '$\\text{F}$', value: 'F'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
